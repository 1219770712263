import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "components/Layout"
import SEO from "components/SEO"
import TextSection from "components/TextSection"
import Background from "components/Background"
import Signup from "components/Signup"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "profile.jpg" } }) {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="About" />
        <h2 className="text-4xl text-center mt-16">
          Engineer / Father / Writer
        </h2>
        <div className="text-xl flex flex-col md:grid md:grid-cols-5 md:grid-flow-row">
          <div
            className="w-full max-w-lg px-12 pt-12 md:pl-4 md:pr-4 mx-auto 
                       mt-8 md:mt-16 order-1 md:col-start-4 md:row-start-1 md:col-span-2"
          >
            <Img
              className="border-white border-4"
              fluid={data.imageSharp.fluid}
            />
          </div>
          <div className="order-2 md:col-start-1 md:col-span-3 md:row-start-1 md:row-span-2">
            <TextSection
              sectionClassName="px-8 pb-0 md:pl-12 md:pr-2"
              bodyClassName="text-xl px-4 py-8 md:mt-12"
            >
              <div className="rounded-sm grid grid-cols-1 gap-8 bg-white border border-gray-500 p-8">
                <div>
                  These are the roles I've held&mdash;first sequentially, now
                  simultaneously.
                </div>
                <div>
                  Once, I was an engineer. I developed software for startups and
                  banks. I rode the subway and had a view of the Empire State
                  Building. I took walks with my wife and decided on the fly if
                  it might be a nice evening for a show.
                </div>
                <div>
                  Two children later, we had less time for walks and less money
                  for shows. Still, fatherhood was bliss, and two children, we
                  could handle. We learned how to navigate subway platforms with
                  a double stroller. We learned which stations had working
                  elevators. Two children, we could handle. But three?
                </div>
                <div>
                  Three and a half years after moving to the midwest, my first
                  story was published. I'd spent the time at home with my
                  youngest, writing while he napped, studying the craft as best
                  I could, and writing more after all three were in bed. I
                  packed school lunches and I wrote some terrible stories, but I
                  wrote a few passable ones, too.
                </div>
                <div>
                  I am now all these things: engineer, father, and writer. It's
                  a balancing act, as everything is, but it's{" "}
                  <span className="italic">my</span> act, and I'm just getting
                  started.
                </div>
                <div>Enjoy the show.</div>
              </div>
            </TextSection>
          </div>
          <div className="order-3 md:col-start-4 md:col-span-2 md:row-start-2 lg:mt-8 px-4 pb-4">
            <Signup />
          </div>
        </div>
      </Layout>
      <Background bandClass="bg-catalog-top" />
    </>
  )
}

export default About
